<template>
    <loader
        :isLoading="isLoadingShow"
        :error="showError"
        errorMessage="campaigns.campaign.showError"
    >
        <v-form ref="form" @submit.prevent="onUpdate">
            <v-card outlined color="primary" class="mb-2">
                <v-card-text>
                    <h2 class="text-center mb-4">
                        {{ $t("campaigns.campaign.campaignInformations") }}
                    </h2>

                    <v-select
                        v-model="campaign.year"
                        :label="$t('campaigns.campaign.year') + '*'"
                        :items="years"
                        :rules="requiredRule"
                        :disabled="haveDoneAssociations"
                    ></v-select>

                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="campaign.start_date"
                                :label="
                                    $t('campaigns.campaign.startDate') + '*'
                                "
                                :error-messages="
                                    requiredDateRule(campaign.start_date)
                                        ? requiredDateRule(campaign.start_date)
                                        : null
                                "
                                type="date"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="campaign.end_date"
                                :label="
                                    $t('campaigns.campaign.endDate') + '*'
                                "
                                :error-messages="
                                    requiredDateRule(campaign.end_date, true)
                                        ? requiredDateRule(
                                              campaign.end_date,
                                              true
                                          )
                                        : null
                                "
                                type="date"
                            ></v-text-field
                        ></v-col>
                    </v-row>
                    <v-text-field
                        v-model="campaign.contact"
                        :label="$t('campaigns.campaign.contact') + '*'"
                        :rules="[
                            emailRule.required(campaign.contact),
                            emailRule.email(campaign.contact),
                        ]"
                    ></v-text-field>
                </v-card-text>
            </v-card>

            <v-card outlined color="primary" class="my-2">
                <v-card-text>
                    <h2 class="text-center mb-4">
                        {{ $t("campaigns.campaign.priceInformations") }}
                    </h2>
                    <span class="black--text">
                        {{ $t("campaigns.campaign.associationFee.text") }}* :
                    </span>
                    <v-row>
                        <v-col class="pt-0">
                            <v-text-field
                                v-model="campaign.association_fee_renew"
                                :label="
                                    $t(
                                        'campaigns.campaign.associationFee.forRenew'
                                    )
                                "
                                :rules="floatNumberRule"
                                type="number"
                                :disabled="haveDoneAssociations"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pt-0">
                            <v-text-field
                                v-model="campaign.association_fee_primo"
                                :label="
                                    $t(
                                        'campaigns.campaign.associationFee.forPrimo'
                                    )
                                "
                                :rules="floatNumberRule"
                                type="number"
                                :disabled="haveDoneAssociations"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <span class="black--text">
                        {{ $t("campaigns.campaign.individualContributions") }}*
                        :
                    </span>
                    <v-text-field
                        v-model="campaign.individual_contributions"
                        :rules="floatNumberRule"
                        type="number"
                        :disabled="haveDoneAssociations"
                    ></v-text-field>

                    <span class="black--text">
                        {{ $t("campaigns.campaign.exceptionalDiscount.text") }}
                        :
                    </span>
                    <v-row>
                        <v-col class="pt-0">
                            <v-text-field
                                v-model="campaign.exceptional_discount_value"
                                :label="
                                    $t(
                                        'campaigns.campaign.exceptionalDiscount.value'
                                    )
                                "
                                :rules="percentNumberRule"
                                type="number"
                                :disabled="haveDoneAssociations"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pt-0">
                            <v-text-field
                                v-model="campaign.exceptional_discount_text"
                                :label="
                                    $t(
                                        'campaigns.campaign.exceptionalDiscount.showText'
                                    )
                                "
                                :disabled="haveDoneAssociations"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <span class="black--text">
                        {{ $t("campaigns.campaign.bfMag.all") }}* :
                    </span>
                    <span v-if="errorBfMag" class="text-center error--text">
                        {{ $t("campaigns.campaign.bfMag.error") }}
                    </span>

                    <v-simple-table class="grey lighten-3">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>
                                        {{
                                            $t(
                                                "campaigns.campaign.bfMag.quantity"
                                            )
                                        }}
                                    </th>
                                    <th>
                                        {{
                                            $t("campaigns.campaign.bfMag.price")
                                        }}
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(line, index) in campaign.bf_mag"
                                    :key="index"
                                >
                                    <td>
                                        <v-text-field
                                            v-model="line.quantity"
                                            type="number"
                                            :disabled="haveDoneAssociations"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="line.price"
                                            type="number"
                                            :disabled="haveDoneAssociations"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-icon
                                            small
                                            @click="deleteBfMagLine(index)"
                                            v-if="!haveDoneAssociations"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </td>
                                </tr>
                                <tr v-if="!haveDoneAssociations">
                                    <td></td>
                                    <td>
                                        <v-icon small @click="addBfMagLine()">
                                            mdi-plus
                                        </v-icon>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>

            <v-card outlined color="primary" class="my-2">
                <v-card-text>
                    <h2 class="text-center mb-4">
                        {{ $t("campaigns.campaign.federationsFees.title") }}
                    </h2>

                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>
                                        {{ $t("federations.federation.name") }}
                                    </th>
                                    <th>
                                        {{
                                            $t(
                                                "campaigns.campaign.federationsFees.fixed"
                                            )
                                        }}
                                    </th>
                                    <th>
                                        {{
                                            $t(
                                                "campaigns.campaign.federationsFees.member"
                                            )
                                        }}
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="line in campaign.federations"
                                    :key="line.id"
                                >
                                    <td>
                                        {{ line.name }}
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="line.fixed"
                                            type="number"
                                            :rules="floatNumberRule"
                                            :disabled="haveDoneAssociations"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="line.member"
                                            type="number"
                                            :rules="floatNumberRule"
                                            :disabled="haveDoneAssociations"
                                        ></v-text-field>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>

            <v-card outlined color="primary" class="mb-2">
                <v-card-text>
                    <h2 class="text-center mb-4">
                        {{ $t("campaigns.campaign.associations") }}
                    </h2>
                    <v-checkbox
                        v-for="association in allAssociations"
                        :key="association.id"
                        v-model="campaign.associations"
                        :value="association"
                        hide-details
                        :disabled="!!association.done"
                    >
                        <template slot="label"
                            >{{ association.name }}
                            <span v-if="association.done" class="ml-1">
                                (
                                {{ $t("campaigns.campaign.associationDone") }}
                                )</span
                            ></template
                        >
                    </v-checkbox>
                    <p
                        v-if="
                            campaign.associations &&
                                campaign.associations.length == 0
                        "
                        class="error--text mt-2"
                    >
                        {{ $t("form.selectOneAssociation") }}
                    </p>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col cols="auto">
                    <v-btn
                        :loading="isLoadingUpdate"
                        color="primary"
                        type="submit"
                    >
                        {{ $t("button.update") }}
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="error" @click="onOpenDeleteDialog()">
                        {{ $t("button.delete") }}
                    </v-btn>
                </v-col>
            </v-row>
            <p v-if="formIsInvalid" class="text-center warning--text">
                {{ $t("form.error") }}
            </p>
            <p v-if="updateError" class="text-center error--text">
                {{ $t("campaigns.update.error") }}
            </p>
        </v-form>

        <v-dialog v-model="deleteDialog" max-width="500">
            <v-card>
                <v-card-title>
                    {{ $t("campaigns.deleteDialog.title") }}
                </v-card-title>

                <v-card-text v-if="isLoadingDelete" class="text-center">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                    <br />
                    {{ $t("campaigns.deleteDialog.isLoading") }}
                </v-card-text>
                <v-card-text v-else-if="errorDelete" class="text-center">
                    {{ $t("campaigns.deleteDialog.error." + errorDelete) }}
                </v-card-text>
                <v-card-text v-else>
                    {{ $t("campaigns.deleteDialog.text") }}
                </v-card-text>

                <v-card-actions v-if="!isLoadingDelete && !errorDelete">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="deleteCampaign()">
                        {{ $t("button.yes") }}
                    </v-btn>
                    <v-btn text @click="deleteDialog = false">
                        {{ $t("button.no") }}
                    </v-btn>
                </v-card-actions>
                <v-card-actions v-if="!isLoadingDelete && errorDelete !== null">
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteDialog = false">
                        {{ $t("button.return") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </loader>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/utils/Loader";

export default {
    components: {
        Loader,
    },

    props: {
        campaignId: {
            type: [String, Number],
            required: true,
        },
    },

    data() {
        return {
            isLoadingShow: true,
            showError: false,

            campaign: {},
            years: [],

            allFederations: [],
            allAssociations: [],

            formIsInvalid: false,

            isLoadingUpdate: false,
            updateError: false,

            deleteDialog: false,
            isLoadingDelete: false,
            errorDelete: null,
        };
    },

    mounted() {
        this.getCampaign();
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
            emailRule: (state) => state.utils.emailRule,
            floatNumberRule: (state) => state.utils.floatNumberRule,
            requiredNumberRule: (state) => state.utils.requiredNumberRule,
            percentNumberRule: (state) => state.utils.percentNumberRule,
            requiredSelectRule: (state) => state.utils.requiredSelectRule,
        }),

        errorBfMag() {
            if (this.campaign && this.campaign.bf_mag) {
                var count = 0;
                for (
                    let index = 0;
                    index < this.campaign.bf_mag.length;
                    index++
                ) {
                    const item = this.campaign.bf_mag[index];
                    if (index == 0 && parseInt(item.quantity) !== 1) {
                        return true;
                    }
                    if (
                        !item.price ||
                        Number.isNaN(item.price) ||
                        parseFloat(item.price) <= 0 ||
                        (item.price.toString().includes(".") &&
                            item.price.toString().split(".")[1].length > 2)
                    ) {
                        return true;
                    }
                    if (
                        !item.quantity ||
                        Number.isNaN(item.quantity) ||
                        parseFloat(item.quantity) <= 0 ||
                        item.quantity.toString().includes(".")
                    ) {
                        return true;
                    }
                    if (count > item.quantity) {
                        return true;
                    }
                }
                return this.campaign.bf_mag.length == 0;
            }
            return false;
        },

        haveDoneAssociations() {
            return (
                this.campaign &&
                this.campaign.associations &&
                this.campaign.associations.some(
                    (association) => association.done == true
                )
            );
        },
    },

    methods: {
        getCampaign() {
            this.isLoadingShow = true;
            this.showError = false;

            this.$store
                .dispatch("ohme/getCampaign", this.campaignId)
                .then((data) => {
                    this.campaign = data.data.campaign;
                    this.years = data.data.years;
                    this.allFederations = data.data.federations;

                    this.allAssociations = this.campaign.associations.map(
                        (x) => x
                    );
                    data.data.allAssociations.forEach((association) => {
                        if (
                            !this.allAssociations.find(
                                (item) => item.id == association.id
                            )
                        ) {
                            this.allAssociations.push(association);
                        }
                    });
                    this.isLoadingShow = false;
                })
                .catch((err) => {
                    if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 403
                    ) {
                        this.$router.push({ name: "403" });
                    } else {
                        this.isLoadingShow = false;
                        this.showError = true;
                    }
                });
        },

        requiredDateRule(value, end = false) {
            if (!value) {
                return this.$t("form.required");
            } else if (
                end &&
                this.campaign.start_date &&
                this.campaign.end_date &&
                this.campaign.start_date > this.campaign.end_date
            ) {
                return this.$t("form.startBeforeEnd");
            }
        },

        deleteBfMagLine(index) {
            this.campaign.bf_mag.splice(index, 1);
        },

        addBfMagLine() {
            this.campaign.bf_mag.push({});
        },

        getFieldName(fieldName, requiredFunction) {
            if (requiredFunction(null)) {
                return fieldName + "*";
            } else {
                return fieldName;
            }
        },

        onUpdate() {
            if (
                this.$refs.form.validate() &&
                this.campaign.associations &&
                this.campaign.associations.length > 0 &&
                this.campaign.start_date &&
                this.campaign.end_date &&
                this.campaign.start_date <= this.campaign.end_date &&
                !this.errorBfMag
            ) {
                this.isLoadingUpdate = true;
                this.updateError = false;

                this.$store
                    .dispatch("ohme/updateCampaign", {
                        form: this.campaign,
                        campaignId: this.campaignId,
                    })
                    .then(() => {
                        this.isLoadingUpdate = false;
                        this.getCampaign();
                    })
                    .catch(() => {
                        this.isLoadingUpdate = false;
                        this.updateError = true;
                    });
            } else {
                this.formIsInvalid = true;
            }
        },

        onOpenDeleteDialog() {
            this.isLoadingDelete = false;
            this.errorDelete = null;
            this.deleteDialog = true;
        },

        deleteCampaign() {
            this.isLoadingDelete = true;
            this.errorDelete = null;

            this.$store
                .dispatch("ohme/deleteCampaign", this.campaignId)
                .then(() => {
                    this.$router.push({ name: "Home" });
                })
                .catch((err) => {
                    if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 403
                    ) {
                        this.errorDelete = "403";
                    } else {
                        this.errorDelete = "error";
                    }
                    this.isLoadingDelete = false;
                });
        },
    },
};
</script>
